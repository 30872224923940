import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Router } from "@angular/router";

import { User } from '../user.model';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  @ViewChild('errorMsgModal') errorMsgModal: ElementRef;

  test: boolean;

  serverPath: string;
  options: {
    headers: HttpHeaders,
    withCredentials: boolean
  };
  user: User;

  constructor(private http: HttpClient,
    private cookieService: CookieService,
    private router: Router) {
    this.test = false;

    this.serverPath = "https://www.calculist.ckcorps.com/BACK-END/public";
    //this.serverPath = "http://localhost:8000";
    this.options = {
      headers: new HttpHeaders(),
      withCredentials: true
    };
    this.options.headers = this.options.headers.append('Content-Type', 'application/x-www-form-urlencoded');
    this.options.headers = this.options.headers.append('X-CSRF-TOKEN', this.cookieService.get("XSRF-TOKEN"));

    this.user = new User();
    this.Has_Access();

  }

  ngOnInit(): void {

    if (this.user.loggedIn) {

      this.Get_Profile_Data();
    }
  }

  Get_Profile_Data() {

    let url = this.serverPath + "/getprofiledata";

    this.http.get(url, this.options).subscribe(
      (val: any) => {
        if (val.success) {
          this.user.Set_Firstname(val.firstname);
          this.user.Set_Lastname(val.lastname);
          this.user.Set_Username(val.username);
          this.user.Set_Display_Name(val.displayName);
          this.user.Set_Email(val.email);
          this.user.Set_Gender( (val.gender=="male"?"férfi":"nő") );
          this.user.Set_Birth_Date(val.birthDate);
          this.user.Set_Access_Level(val.accessLevel);
          console.log(this.user.accessLevel);
        }
        (this.test ? console.log(val) : "");
      },
      response => {

      },
      () => {
        //console.log("The POST observable is now completed.");
      });
  }

  Has_Access() {
    if (!this.user.loggedIn) {
      this.router.navigate(['/Home']);
    }
  }

  Delete_Login_Data(){
      this.cookieService.delete('usernameOrEmail');
      this.cookieService.delete('password');

    let that = this;
    setTimeout(function () {
      that.errorMsgModal.nativeElement.click();
    }, 100);

  }

}
