<div class="row justify-content-center mb-5">

  <div class="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8
       text-center -content-Element my-2 py-2">
    <h1 class="-unique-Font"><i class="fas fa-edit ml-2 mr-1"></i> Lista szerkesztő</h1>
  </div>

  <div class="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8
       text-center -content-Element my-2 py-2">
    <small>
      A listán történő módosítások csak abban az esetben mentődnek el, ha rányom a mentés gombra!
      Amennyiben menteni szeretne, a csillaggal ( * ) jelölt mezők kitöltése kötelező!
      <br>
      A törölt termékeket lehetősége van visszaállítani mindaddig amig el nem hagyja az oldalt.
    </small>
  </div>

  <div class="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8
       -content-Element my-2 py-2">
    <div class="row">
      <div class="col-12 mx-auto -list-Item text-center">Lista neve*</div>
      <input type="text" class="col-11 col-md-9 -list-Item-Value mx-auto ml-md-3 mr-md-auto my-2" [(ngModel)]="list.listName">
      <button class="col-11 col-md-2 btn btn-outline-success mx-auto mr-md-3 ml-md-auto  my-2"
              (click)="Form_Check()">
        <i class="fas fa-save mr-1"></i> Mentés
      </button>
    </div>

  </div>

  <div class="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8
       -content-Element my-2 py-2">
    <div class="row">
      <div class="col-12 mx-auto mb-2 -list-Item text-center">Megjegyzés</div>
      <textarea class="col-11 -list-Item-Value mx-auto text-white my-2" rows="4" [(ngModel)]="list.listDescription"></textarea>
    </div>

  </div>

  <div class="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8
       text-center -content-Element my-2 py-2">
    <app-ads></app-ads>
  </div>

  <div class="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8
       -content-Element my-2 py-2" #stickyTopPrice
       [ngClass]="{'bg-dark sticky-top': stickyTop}">
    <div class="row">
      <h6 class="col-12 col-md-6 text-left">Részösszeg: </h6>
      <h6 class="col-12 col-md-6 text-right"> {{ list.listSubPrice }} <i class="fas fa-money-bill-wave ml-1"></i></h6>
    </div>

    <hr class="bg-white my-2">

    <div class="row">
      <h4 class="col-12 col-md-6 text-left">Végösszeg: </h4>
      <h4 class="col-12 col-md-6 text-right"> {{ list.listFullPrice }} <i class="fas fa-wallet ml-1"></i></h4>
    </div>

    <div class="row custom-control custom-switch">
      <div class="col-12">
        <input type="checkbox" class="custom-control-input"
               [(ngModel)]="floatPrice" value="true" id="switch1"
               (change)="Listen_Sticky_Top_Price()">
        <label class="custom-control-label" for="switch1">Lebegjen</label>
      </div>
    </div>

  </div>

  <div class="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8
       -content-Element p-4 my-2">

    <app-listitem *ngFor="let myProduct of list.listProducts"
                  [product]="myProduct"
                  [productUnits]="productUnits"
                  (Refresh_Data)="Refresh_Data($event)">

    </app-listitem>

    <button class="btn btn-block btn-outline-success my-3"
            (click)="Add_New_Product()">
      <i class="fas fa-plus-circle"></i> Termék hozzáadása
    </button>

  </div>

  <div class="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8
       text-center -content-Element mb-5 mb-md-0 mt-2 py-2">
    <app-ads></app-ads>
  </div>

</div>


<!--ERROR MSG MODAL-->
<div class="modal fade" id="_Error_Msg_Modal" tabindex="-2" role="dialog" aria-hidden="true" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered" role="document" id="_Modal_Cookie_Dialog">
    <div class="modal-content bg-dark text-white mx-3" id="_Modal_Cookie_Content">
      <div class="modal-header" id="_Modal_Cookie_Header">
        <h5 class="modal-title w-100 text-center text-warning" id="_Modal_Cookie_H5">
          {{ errorMsgType }}
        </h5>
      </div>
      <div class="modal-body" id="_Modal_Cookie_Body">

        <p class="text-justify mx-3" innerHtml="{{ errorMsg }}">

        </p>

      </div>
      <div class="modal-footer" id="_Modal_CK_Cookie_Footer">
        <button type="button" class="btn btn-block btn-outline-secondary text-white mx-3 mb-3"
                id="_Accept_Cookie_Btn" data-dismiss="modal" #closeCookieModal>
          <i class="fas fa-check-double"></i> Rendben
        </button> <!--data-dismiss="modal"-->
      </div>
    </div>
  </div>
</div>

<button class="btn -hide-Element" data-toggle="modal"
        data-target="#_Error_Msg_Modal"
        type="submit" #errorMsgModal>
</button>
