import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AdminComponent } from './admin/admin.component';
import { ProfileComponent } from './profile/profile.component';
import { EditorComponent } from './editor/editor.component';
import { MylistComponent } from './mylist/mylist.component';
import { LegalComponent } from './legal/legal.component';

const routes: Routes = [
  {
    path: 'Home',
    component: HomeComponent,
    
  },
  {
    path: '',
    component: HomeComponent,
    
  },
  {
    path: 'Profile',
    component: ProfileComponent
  },
  {
    path: 'Editor',
    component: EditorComponent
  },
  {
    path: 'Editor/:listNumber',
    component: EditorComponent
  },
  {
    path: 'Mylist',
    component: MylistComponent
  },
  {
    path: 'Legal',
    component: LegalComponent
  },
  {
    path: 'Admin',
    component: AdminComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
