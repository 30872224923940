<div class="row justify-content-center mb-5">

  <div class="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8
       text-center -content-Element my-2 py-2">
    <h1 class="-unique-Font"><i class="fas fa-home mr-1"></i> Kezdőoldal</h1>
  </div>

  <div class="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8
       -content-Element my-2 py-2">
    <div class="col-12">

      <h4 class="col-11 mx-auto text-center my-4 mb-5 font-weight-bold font-italic">Üdvözöljük a CalcuList weboldalán!</h4>

      <div *ngIf="!user.loggedIn"
           class="alert alert-primary text-center col-11 mx-auto my-4" role="alert">
        Kérem jelentkezzen be, hogy élvezni tudja a webalkalamazás által nyújtotta összes lehetőséget!
      </div>

      <h5 class="col-11 mx-auto text-left my-4 -underline">Mi az a CalcuList?</h5>

      <div class="col-11 mx-auto text-justify">
        A CalcuList egy webalkalmazás amely segítségével minden bevásárlás előtt bevásárlólistát tud készíteni,
        hogy ne felejtse el mire van szüksége.
        <br><br>
        Lehetősége van megadni minden bevásárolandó teméknek az egységenkénti árát (pl: ár/kg vagy ár/csomag), beírhatja hogy az
        adott termékből mennyire van szüksége majd az alkalmazás kiszámolja a bevásárlólista végösszegét.
        <br>
        Továbbá azt is feljegyezheti minden egyes terméknél, hogy hol szeretné azt megvenni.
        <br><br>
        Bevásárlás közben a listában szereplő termékekre be tudja állítani, hogy a kosárban vannak e már és ezáltal
        a kosárban szereplő termékek részárát is láthatja.
      </div>

      <div class="col-11 mx-auto text-justify mt-4">
        <app-ads></app-ads>
      </div>

      <h5 class="col-11 mx-auto text-left my-4 -underline">Milyen előnnyel jár a regisztráció?</h5>

      <div class="col-11 mx-auto text-justify">
        Amennyiben regisztrál és bejelentkezve használja az almalmazást, lehetősége van elmenteni a létrehozott listát
        amit később bármikor újra felhasználhat!
        <br><br>
        Létrehozhat és tárolhat bármennyi listát amennyire csak szüksége van! A létrehozott listákat szerkesztheti is!
        <br><br>
        Sőt a<span class="font-weight-bold"> létrehozott listáit akár meg is oszthatja</span> barátaival, családtagjaival
        amennyiben ők is rendelkeznek felhasználói fiókkal.
      </div>

      <div class="col-11 mx-auto text-justify mt-4">
        <app-ads></app-ads>
      </div>

      <h5 class="col-11 mx-auto text-left my-4 -underline">Mire figyeljen bejelentkezéskor?</h5>

      <div class="col-11 mx-auto text-justify">
        Bejelenkezéskor lehetsége van bejelölni a "Jegyezd meg az adataim" nevű jelölőnégyzetet amely arra szolgál, hogy
        a böngészője memóriájába (Cookie-ba) elmenti a felhasználónevét és a jelszavát.
        <br><br>
        <span class="font-weight-bold">
          Fontos, hogy ezt a funkciót, csak a saját privát eszközén használja,
          mivel e opció használata csökkenti fiókja biztonságát! Amennyiben más eszközén bejelentkezve marad, illetéktelen
          személyek is hozzáférhetnek a jelszavához!
        </span>
        <br><br>
        Ez a funckió alapértelmezetten mindig ki van kapcsolva. Amennyiben elmentette az adatait és törölni szeretné, ezt a
        profil oldalon a "Bejelentkezési adataim törlése" gombra kattintva teheti meg!
      </div>

    </div>

  </div>

  <div class="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8
       text-center -content-Element mb-5 mb-md-0 mt-2 py-2">
    <app-ads></app-ads>
  </div>

</div>
