<div class="row my-2 py-2 -component">

  <div class="col-8 text-center">{{ product.productName }}</div>
  <button class="btn col-3 mr-2 ml-auto"
          [ngClass]="{'btn-success': !product.productState, 'btn-danger': product.productState}"
          (click)="Refresh_Grandparent_Data(product.productId, 'change-state')">
    <i class="fas fa-times-circle" *ngIf="product.productState; else _Disable_Product"></i>
    <ng-template #_Disable_Product>
      <i class="fas fa-shopping-cart"></i>
    </ng-template>
  </button>
  <div class="col-12 text-center">{{ product.productPrice }} / {{ product.productUnit }}</div>
  <div class="col-12 text-center">{{ product.productQuantity }} {{ product.productUnit }}</div>
  <div class="col-12 text-center">{{ product.productLocation }}</div>

  <div class="row w-100 text-white mt-1 mx-auto sticky-top">
    <div class="col-12 text-right ml-auto mr-2"><small class="-dotted-Place">ár: {{ product.productFullPrice }}</small></div>
  </div>

</div>



