export class Product {

  productId: number;
  productIdDataBase: number;
  productOwnerListIdDataBase: number;

  productName: string;
  productPrice: number;
  productQuantity: number;
  productUnit: string;
  productLocation: string;
  productState: boolean;

  productFullPrice: number;
  deleted: boolean;

  constructor(productId: number, productName: string, productPrice: number=0,
    productQuantity: number=0, productUnit: string, productLocation: string,
    productState: boolean=false) {

    this.productId = productId;
    this.productIdDataBase = null;

    this.productName = productName;
    this.productPrice = productPrice;
    this.productQuantity = productQuantity;
    this.productUnit = productUnit;
    this.productLocation = productLocation;
    this.productState = productState;
    this.deleted = false;

    this.Product_Full_Price();
  }

  /* Overwrite product after saving in the editor */
  Init(newProduct: Product) {

//    this.productId = newProduct.productId;
    this.productIdDataBase = newProduct.productIdDataBase;
    this.productOwnerListIdDataBase = newProduct.productOwnerListIdDataBase;

    this.productName = newProduct.productName;
    this.productPrice = newProduct.productPrice;
    this.productQuantity = newProduct.productQuantity;
    this.productUnit = newProduct.productUnit;
    this.productLocation = newProduct.productLocation;
    this.productState = newProduct.productState;
    this.deleted = newProduct.deleted;

    this.Product_Full_Price();
  }


  Product_Full_Price(): void{

    /* Replace "," to "." */
    let number;
    if (this.productQuantity !== null) {
      number = this.productQuantity.toString();
      this.productQuantity = number.replace(",", ".");
    }
    if (this.productPrice !== null) {
      number = this.productPrice.toString();
      this.productPrice = number.replace(",", ".");
    }

    this.productFullPrice = (this.productQuantity * this.productPrice);
    this.productFullPrice = Math.round((this.productFullPrice + Number.EPSILON) * 100) / 100;
  }

  Delete(): void{
    this.deleted = true;
  }

  Restore(): void{
    this.deleted = false;
  }

  Set_Unit(productUnit: string): void{
    this.productUnit = productUnit;
  }

  Change_State(): void{

    if (this.productState) {

      this.productState = false;
    } else {

      this.productState = true;
    }
  }

}
