import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Product } from '../product.model';

@Component({
  selector: 'app-listitem',
  templateUrl: './listitem.component.html',
  styleUrls: ['./listitem.component.css']
})
export class ListitemComponent implements OnInit {

  @Input() product: Product;
  @Input() productUnits: string[];
  @Output("Refresh_Data") Refresh_Data: EventEmitter<{ pId: number, pAction: string, pNewValue: any }> = new EventEmitter();


  constructor() {

  }

  ngOnInit(): void {

  }

  Refresh_Parent_Data(id: number, action: string, newVal: any=0) {
    this.Refresh_Data.emit({ pId: id, pAction: action, pNewValue: newVal });
  }

}
