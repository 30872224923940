import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

import { List } from '../list.model';
import { Product } from '../product.model';
import { User } from '../user.model';


@Component({
  selector: 'app-mylist',
  templateUrl: './mylist.component.html',
  styleUrls: ['./mylist.component.css']
})

export class MylistComponent implements OnInit {

  @ViewChild('errorMsgModal') errorMsgModal: ElementRef;
  @ViewChild('shareListModal') shareListModal: ElementRef;
  @ViewChild('deleteListModal') deleteListModal: ElementRef;

  test: boolean;
  errorMsg: string;
  errorMsgType: string;
  tmpListId: string;
  tmpUsername: string;

  list: List[];
  productList: Product[];
  user: User;

  serverPath: string;
  options: {
    headers: HttpHeaders,
    withCredentials: boolean
  };
  body: HttpParams;

  constructor(private router: Router,
    private http: HttpClient,
    private cookieService: CookieService) {

    this.test = false;

    this.serverPath = "https://www.calculist.ckcorps.com/BACK-END/public";
    //this.serverPath = "http://localhost:8000";
    this.options = {
      headers: new HttpHeaders(),
      withCredentials: true
    };
    this.body = new HttpParams();

    this.options.headers = this.options.headers.append('Content-Type', 'application/x-www-form-urlencoded');
    this.options.headers = this.options.headers.append('X-CSRF-TOKEN', this.cookieService.get("XSRF-TOKEN"));

    this.productList = Array();
    this.list = Array();

    this.user = new User();
    this.Has_Access();

    this.Get_Lists();

  }

  ngOnInit(): void {
  }

  Get_Lists() {

    let url = this.serverPath + "/getlists";
    this.options.headers = this.options.headers.set('X-CSRF-TOKEN', this.cookieService.get("XSRF-TOKEN"));

    this.http.get(url, this.options).subscribe(
      (val: { success: boolean, value: List[] }) => {

        if (val.success) {

          this.Generate_Lists(val.value);
        }

        (this.test ? console.log(val) : "");
      },
      response => {

      },
      () => {
        //console.log("The POST observable is now completed.");
      });
  }

  Delete_List(listId: string): void {

    let url = this.serverPath + "/deletelistbyid";
    this.options.headers = this.options.headers.set('X-CSRF-TOKEN', this.cookieService.get("XSRF-TOKEN"));
    this.body = this.body.set('listId', listId);

    this.http.post(url, this.body, this.options).subscribe(
    (val: { success: boolean, value: List[] }) => {

      if (val.success) {

        this.Get_Lists();
      }else{

      }

      (this.test ? console.log(val) : "");
    },
    response => {

    },
    () => {
      //console.log("The POST observable is now completed.");
    });
  }

  Share_List(): void {

    let url = this.serverPath + "/sharelist";
    this.options.headers = this.options.headers.set('X-CSRF-TOKEN', this.cookieService.get("XSRF-TOKEN"));
    this.body = this.body.set('listId', this.tmpListId);
    this.body = this.body.set('username', this.tmpUsername);

    this.http.post(url, this.body, this.options).subscribe(
    (val: { success: boolean, value: List[] }) => {

      if (val.success) {
        this.errorMsgType = "Válaszüzenet";
        this.errorMsg = "Sikeres listamegosztás!";
        this.Show_Error();
      }else{
        this.errorMsgType = "Hibaüzenet";
        this.errorMsg = "Sikertelen listamegosztás! Felhasználó nem található.";
        this.Show_Error();
      }

      (this.test ? console.log(val) : "");
    },
    response => {

    },
    () => {
      //console.log("The POST observable is now completed.");
    });
  }

  Change_List_Status(listId: string, listState: string): void {

    let url = this.serverPath + "/setliststate";
    this.options.headers = this.options.headers.set('X-CSRF-TOKEN', this.cookieService.get("XSRF-TOKEN"));
    this.body = this.body.set('listId', listId);
    this.body = this.body.set('listState', listState);

    this.http.post(url, this.body, this.options).subscribe(
    (val: { success: boolean, value: List[] }) => {

      if (val.success) {

      }else{

      }

      (this.test ? console.log(val) : "");
    },
    response => {

    },
    () => {
      //console.log("The POST observable is now completed.");
    });
  }

  Add_New_Product(id: number, name: string, price: number, quantity: number,
    unit: string, location: string, state: boolean) {

    if (id === -111) {
      id = this.productList.length;
    }

    this.productList.push(new Product(id, name, price, quantity, unit, location, state));
  }


  Add_New_List(id: number, idDataBase: number, name: string, description: string, author: string,
    listOwner: string, productList: Product[], listState: string) {

    if (id === -111) {
      id = this.list.length;
    }

    this.list.push(new List(id, name, description, author, listOwner, productList, listState));
    this.list[this.list.length - 1].listIdDataBase = idDataBase;
  }

  Generate_Lists(lists: List[]) {

    this.productList = Array();
    this.list = Array();

    for (let i = 0; i < lists.length; i++) {

      for (let j = 0; j < lists[i].listProducts.length; j++) {

        this.Add_New_Product(-111, lists[i].listProducts[j].productName,
          lists[i].listProducts[j].productPrice, lists[i].listProducts[j].productQuantity,
          lists[i].listProducts[j].productUnit, lists[i].listProducts[j].productLocation,
          lists[i].listProducts[j].productState);
      }
      this.Add_New_List(-111, lists[i].listIdDataBase, lists[i].listName, lists[i].listDescription, lists[i].listAuthor,
        this.user.username, this.productList, lists[i].listState);

      this.Clear_Product_List();
    }
  }

  Clear_Product_List() {
    this.productList = Array();
  }

  Refresh_Data($event) {
    switch ($event.pAction) {
      case 'change-state':
        this.list[$event.lId].listProducts[$event.pId].Change_State();
        this.list[$event.lId].Calculate_Prices();
        break;
      case 'share-list':
        this.tmpListId = $event.lId;
        this.errorMsgType = "\"" + $event.newVal + "\" nevű lista megosztása a következő felhasználóval: ";
        this.Share_List_Modal();
        break;
      case 'delete-list':
        this.Delete_List_Modal($event.lId, $event.newVal);
        break;
      case 'modify-list':
        this.router.navigate(['/Editor', $event.lId]);
        break;
      case 'change-status':
        this.Change_List_Status($event.lId, $event.newVal);
        break;

    }
    //alert("listId:" + $event.lId + ", productId:" + $event.pId + ", action:" + $event.pAction + ", newVal:" + $event.newVal);
  }

  Has_Access() {
    if (!this.user.loggedIn) {
      this.router.navigate(['/Home']);
    }
  }

  Show_Error() {

    let that = this;
    setTimeout(function () {
      that.errorMsgModal.nativeElement.click();
    }, 100);
  }

  Delete_List_Modal(listId: string, listName: string) {

    this.tmpListId = listId;
    this.errorMsgType = "";
    this.errorMsg = "Biztos törli a kövertkező listát: \"" + listName + "\"?" 

    let that = this;
    setTimeout(function () {
      that.deleteListModal.nativeElement.click();
    }, 100);
  }

  Yes_Delete_List(){

    this.Delete_List(this.tmpListId);
  }

  Share_List_Modal() {

    let that = this;
    setTimeout(function () {
      that.shareListModal.nativeElement.click();
    }, 100);
  }


}
