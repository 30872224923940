import { Component, OnInit, HostListener } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute } from '@angular/router';

import { List } from '../list.model';
import { Product } from '../product.model';
import { User } from '../user.model';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css']
})
export class EditorComponent implements OnInit {

  @ViewChild('stickyTopPrice') stickyTopPrice: ElementRef;
  @ViewChild('errorMsgModal') errorMsgModal: ElementRef;

  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    this.Listen_Sticky_Top_Price();
  }

  test: boolean;

  list: List;
  productList: Product[];
  user: User;
  stickyTop: boolean;
  productUnits: string[];
  floatPrice: boolean;
  errorMsg: string;
  errorMsgType: string;

  paramListId: string;

  serverPath: string;
  options: {
    headers: HttpHeaders,
    withCredentials: boolean
  };
  body: HttpParams;

  constructor(private http: HttpClient,
    private cookieService: CookieService,
    private _Activatedroute: ActivatedRoute) {

    this.test = false;

    this.user = new User();
    this.stickyTop = false;
    this.floatPrice = true;
    this.productUnits = Array();

    this.serverPath = "https://www.calculist.ckcorps.com/BACK-END/public";
    //this.serverPath = "http://localhost:8000";
    this.options = {
      headers: new HttpHeaders(),
      withCredentials: true
    };
    this.body = new HttpParams();
    this.options.headers = this.options.headers.append('Content-Type', 'application/x-www-form-urlencoded');
    this.options.headers = this.options.headers.append('X-CSRF-TOKEN', this.cookieService.get("XSRF-TOKEN"));

    this.paramListId = this._Activatedroute.snapshot.paramMap.get("listNumber");

    this.productList = Array();
    this.Init_List(); 

    this.Add_New_Product();
    
    this.Listen_Sticky_Top_Price();
    this.Get_Product_Units()

    this.Is_Recieved_List();
  }

  ngOnInit(): void {

    /*
    setInterval(() => {
      console.log(this.list);
    }, 5000);
    */
  }

  Init_List(id: number=0, name: string = "", description: string = "") {
    this.list = new List(id, name, description, this.user.username, this.user.username, this.productList);
  }

  Add_New_Product(id: number=-111, name: string = "", price: number = 0, quantity: number = 0,
    unit: string = "db", location: string = "", state: boolean = false) {

    if (id === -111) {
      id = this.productList.length;
    }

    this.productList.push(new Product(id, name, price, quantity, unit, location, state));
  }

  Refresh_Data($event) {
    switch ($event.pAction) {
      case 'change-price':
        this.list.Calculate_Prices();
        break;
      case 'delete-element':
        this.list.listProducts[$event.pId].Delete();
        this.list.Calculate_Prices();
        break;
      case 'restore-element':
        this.list.listProducts[$event.pId].Restore();
        this.list.Calculate_Prices();
        break;
      case 'change-unit':
        this.list.listProducts[$event.pId].Set_Unit($event.pNewValue);
        break;
      case 'change-state':
        this.list.listProducts[$event.pId].Change_State();
        this.list.Calculate_Prices();
        break;
    }
    //alert("id:" + $event.pId + ", action:" + $event.pAction + ", new value:" + $event.pNewValue);
  }

  Listen_Sticky_Top_Price() {

    let that = this;

    setTimeout(function () {

      let topOffset = that.stickyTopPrice.nativeElement.getBoundingClientRect().top;

      if (screen.width >= 768) {

        if (topOffset <= 61) {
          if (that.floatPrice) {
            that.stickyTop = true;
          } else {
            that.stickyTop = false;
          }
        } else {
          that.stickyTop = false;
        }

      } else {

        if (topOffset <= 1) {
          if (that.floatPrice) {
            that.stickyTop = true;
          } else {
            that.stickyTop = false;
          }
        } else {
          that.stickyTop = false;
        }

      }

    }, 100);
  }


  Get_Product_Units() {

    let url = this.serverPath + "/api/getitemunits";

    this.http.get(url, this.options).subscribe(
      (val: any) => {
        for (let i = 0; i < val.length; i++) {
          this.productUnits.push(val[i]);
        }
        (this.test ? console.log(val) : "");
      },
      response => {

      },
      () => {
        //console.log("The POST observable is now completed.");
      });
  }

  Form_Check() {

    this.user.Init();
    this.errorMsgType = "Hibaüzenet";

    if (!this.user.loggedIn) {

      this.errorMsg = "Ahhoz, hogy el tudja menteni a bevásárlólistát, előbb be kell jelentkeznie."
      this.Show_Error();
      return false;
    }

    let formValidity = this.list.Check_Format_Validity();

    if (formValidity !== true) {

      this.errorMsg = formValidity;
      this.Show_Error();
      return false;
    } else {

      this.Save_List();
    }

  }

  Save_List() {

    this.list.listAuthor = this.user.username;
    this.list.listOwner = this.user.username;

    let url = this.serverPath + "/savelist";
    this.body = this.body.set('list', JSON.stringify(this.list));
    this.options.headers = this.options.headers.set('X-CSRF-TOKEN', this.cookieService.get("XSRF-TOKEN"));

    this.http.post(url, this.body, this.options).subscribe(
      (val: { success: boolean, value: List }) => {
        if (val.success) {
          this.list.Init(val.value);
          this.errorMsgType = "Válaszüzenet";
          this.errorMsg = "Sikeres mentés!"
          this.Show_Error();
        } else {
          this.list.Init(val.value);
          this.errorMsgType = "Hibaüzenet";
          this.errorMsg = "Sikertelen mentés!"
        }

        (this.test ? console.log(val) : "");
      },
      response => {

      },
      () => {
        //console.log("The POST observable is now completed.");
      });
  }

  Show_Error() {

    let that = this;
    setTimeout(function () {
      that.errorMsgModal.nativeElement.click();
    }, 100);
  }

  Is_Recieved_List() {

    if (this.paramListId === null) {

      return false;
    }

    let listId = Number(this.paramListId) - 123;

    let url = this.serverPath + "/getlistbyid";
    this.body = this.body.set('listId', listId.toString());
    this.options.headers = this.options.headers.set('X-CSRF-TOKEN', this.cookieService.get("XSRF-TOKEN"));
    
    this.http.post(url, this.body, this.options).subscribe(
      (val: { success: boolean, value: List }) => {
        console.log(val.value[0])
        if (val.success) {
          this.list.Init(val.value[0]);
        } else {
          this.errorMsgType = "Hibaüzenet";
          this.errorMsg = "Sikertelen listabetöltés!"
          this.Show_Error();
        }

        (this.test ? console.log(val) : "");
      },
      response => {

      },
      () => {
        //console.log("The POST observable is now completed.");
      });

  }

}
