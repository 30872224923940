import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { List } from '../list.model';

@Component({
  selector: 'app-completelist',
  templateUrl: './completelist.component.html',
  styleUrls: ['./completelist.component.css']
})
export class CompletelistComponent implements OnInit {

  @Input() list: List;
  @Output("Refresh_Data") Refresh_Data: EventEmitter<{ pId: number, lId: number, pAction: string, newVal: string }> = new EventEmitter();

  constructor() {
    console.log(this.list);
  }

  ngOnInit(): void {
  }

  Transfer_To_Parent($event) {
    this.Refresh_Data.emit($event);
  }

  Refresh_Data_Child_To_Parent($event) {
    this.Transfer_To_Parent({ pId: $event.pId, lId: this.list.listId, pAction: $event.pAction, newVal: "" });
  }

  Refresh_Data_FHere_To_Parent(pId: number, lId: number, pAction: string, newVal: string) {
    this.Transfer_To_Parent({ pId: 0, lId: lId, pAction: pAction, newVal: newVal });
  }

}
