export class User {

  firstname: string;
  lastname: string;
  username: string;
  displayName: string;
  password: string;
  email: string;
  gender: string;
  birthDate: string;
  usernameOrEmail: string;
  loggedIn: boolean;
  accessLevel: number;

  constructor() {

    this.Set_Firstname();
    this.Set_Lastname();
    this.Set_Username();
    this.Set_Display_Name();
    this.Set_Email();
    this.Set_Logged_In();
    this.Set_Access_Level();
  }

  Init(): void{

    this.Set_Firstname();
    this.Set_Lastname();
    this.Set_Username();
    this.Set_Display_Name();
    this.Set_Email();
    this.Set_Logged_In();
  }

  Set_Firstname(firstname: string = null): void{
    this.firstname = firstname;
  }

  Set_Lastname(lastname: string = null): void{
    this.lastname = lastname;
  }

  Set_Username(username: string = null): void{
    if (username !== null) {
      this.username = username;
      sessionStorage.setItem('username', username);
    } else if (sessionStorage.getItem('username') !== null) {
      this.username = sessionStorage.getItem('username');
    } else {
      this.username = null;
    }
  }

  Set_Display_Name(displayName: string = null): void{
    this.displayName = displayName;
  }

  Set_Email(email: string = null): void{
    if (email !== null) {
      this.email = email;
      sessionStorage.setItem('email', email);
    } else if (sessionStorage.getItem('email') !== null) {
      this.email = sessionStorage.getItem('email');
    } else {
      this.email = null;
    }
  }

  Set_Logged_In(loggedIn: boolean = null): void{
    if (loggedIn !== null) {
      this.loggedIn = loggedIn;
      sessionStorage.setItem('loggedIn', loggedIn.toString());
    } else if (sessionStorage.getItem('loggedIn') !== null) {
      this.loggedIn = sessionStorage.getItem('loggedIn') === 'true';
    } else {
      this.loggedIn = false;
    }
  }

  Set_Access_Level(accessLevel: number = 0): void{
    this.accessLevel = accessLevel;
  }

  Logout(): void{

    sessionStorage.clear();

    this.Set_Firstname();
    this.Set_Lastname();
    this.Set_Username();
    this.Set_Display_Name();
    this.Set_Email();
    this.Set_Logged_In();
  }

  Set_Gender(gender: string = null): void{
    this.gender = gender;
  }

  Set_Birth_Date(birthDate: string = null): void{
    this.birthDate = birthDate;
  }
}
