import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ViewChild, ElementRef } from '@angular/core';
import { Router } from "@angular/router";

import { CookieService } from 'ngx-cookie-service';

import { User } from '../user.model';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  @ViewChild('closeLoginModal') closeLoginModal: ElementRef;

  test: boolean;

  myUrl: string;
  serverPath: string;
  options: {
    headers: HttpHeaders,
    withCredentials: boolean
  };
  body: HttpParams;
  user: User;
  
  loginErrorVisiblity: boolean;
  loginErrorMsg: string;
  loginSubmitted: boolean;
  saveLoginData: boolean;

  constructor(private http: HttpClient,
    private cookieService: CookieService,
    private router: Router){
    /* VARIABLES */
    this.test = false;

    this.myUrl = window.location.href;
    this.serverPath = "https://www.calculist.ckcorps.com/BACK-END/public";
    //this.serverPath = "http://localhost:8000";
    this.options = {
      headers: new HttpHeaders(),
      withCredentials: true
    };
    this.options.headers = this.options.headers.append('Content-Type', 'application/x-www-form-urlencoded');
    this.options.headers = this.options.headers.append('Access-Control-Allow-Origin', this.serverPath);

    this.body = new HttpParams();

    this.user = new User();

    this.loginErrorVisiblity = false;
    this.loginErrorMsg = "";
    this.loginSubmitted = false;
    this.saveLoginData = false;

    /* FUNCTIONS */
    this.Get_Server_Token();
    this.Controll_Logged_In();
  }

  ngOnInit(): void {
    
  }


  User_Is_Logged_In(): void {

    let url = this.serverPath + "/isloggedin";
    this.options.headers = this.options.headers.set('X-CSRF-TOKEN', this.cookieService.get('XSRF-TOKEN'));

    this.http.post(url, this.body, this.options).subscribe(
      (val: any) => {
        if (val.loggedIn) {
          this.user.Set_Logged_In(true);
          this.user.Set_Username(val.username);
          this.user.Set_Email(val.email);
        } else {
          this.user.Logout();
          this.Read_Login_From_Cookie();
          if (this.router.url === '/Profile' || this.router.url === '/Mylist'|| this.router.url === '/Admin') {
            this.router.navigate(['/Home']);
          }
        }
        (this.test ? console.log(val) : "");
      },
      response => {

      },
      () => {
        //console.log("The POST observable is now completed.");
      });

  }

  Controll_Logged_In() {
    //Check is the user logged in (has a session) in every 20 sec

    let that = this;
    setInterval(function () {

      that.User_Is_Logged_In();
    }, 20000);
  }

  User_Login(): void {

    let url = this.serverPath + "/login";
    this.body = this.body.set('usernameOrEmail', this.user.usernameOrEmail);
    this.body = this.body.set('password', this.user.password);

    this.http.post(url, this.body, this.options).subscribe(
      (val: any) => {
        if (val.success) {
          this.Close_Login_Modal();
          this.user.Set_Logged_In(true);
          this.user.Set_Username(val.username);
          this.user.Set_Email(val.email);
        } else {
          this.user.Logout();
        }
        this.loginSubmitted = val.success;
        this.Set_Error_Msg(val.cause);
        (this.test ? console.log(val) : "");
      },
      response => {
        this.loginSubmitted = false;
        this.Set_Error_Msg("Sajnáljuk, valami hiba történt a bejelentkezés során! Kérem jelentse a hibát az ügyfélszolgálatnál! (ERROR_CODE_1000)");
      },
      () => {
        //console.log("The POST observable is now completed.");
      });

  }

  User_Logout(): void {

    let url = this.serverPath + "/logout";

    this.http.post(url, this.body, this.options).subscribe(
      (val: any) => {
        this.user.Logout();
        this.loginErrorVisiblity = false;
        this.loginErrorMsg = "";
        this.loginSubmitted = false;
        this.Get_Server_Token();
      },
      response => {

      },
      () => {
        //console.log("The POST observable is now completed.");
      });
  }

  Try_Login(): void {

    this.loginSubmitted = true;

    if (!this.Form_Check()) {
      this.loginSubmitted = false;
      return;
    }

    this.Save_Login_Data();

    this.User_Login();
  }

  Close_Login_Modal() {

    //close login modal after the error message has been read
    let that = this;
    setTimeout(function () {
      that.closeLoginModal.nativeElement.click();
    }, 2000);
  }

  Set_Error_Msg(msg: string): void {

    this.loginErrorMsg = msg;
    this.loginErrorVisiblity = true;
  }

  Form_Check(): boolean {

    let msg = "";

    if (this.user.usernameOrEmail.length < 3 || this.user.usernameOrEmail.length > 50) {

      msg = "Az felhasználónév / email 3 és 50 karakter között lehet!";
      this.Set_Error_Msg(msg);
      return false;
    }

    if (this.user.password.length < 6 || this.user.password.length > 32) {

      msg = "Az jelszó 6 és 32 karakter között lehet!";
      this.Set_Error_Msg(msg);
      return false;
    }

    msg = "" +
      "<div class='spinner-grow text-light' role = 'status' >" +
      " <span class='sr-only' > Loading...</span>" +
      "</div>";
    this.Set_Error_Msg(msg);
    return true;
  }

  Get_Server_Token(): void {

    let url = this.serverPath + "/gettoken";
    this.options.headers = this.options.headers.delete('X-CSRF-TOKEN');

    this.http.get(url, this.options).subscribe(
      (val: any) => {
        this.options.headers = this.options.headers.append('X-CSRF-TOKEN', val);
        this.cookieService.set('XSRF-TOKEN', val);
        this.User_Is_Logged_In();
        (this.test ? console.log(val) : "");
      },
      response => {

      },
      () => {
        //console.log("The POST observable is now completed.");
      });
  }

  Save_Login_Data() {
    if (this.saveLoginData) {
      this.cookieService.set('usernameOrEmail', this.user.usernameOrEmail);
      this.cookieService.set('password', this.user.password);
    }
  }

  Read_Login_From_Cookie() {

    if (this.cookieService.check("usernameOrEmail")) {
      this.user.usernameOrEmail = this.cookieService.get("usernameOrEmail");
    }

    if (this.cookieService.check("password")) {
      this.user.password = this.cookieService.get("password");
    }
  }

}

