import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-cookiealert',
  templateUrl: './cookiealert.component.html',
  styleUrls: ['./cookiealert.component.css']
})
export class CookiealertComponent implements OnInit {

  @ViewChild('openCookieAcceptModal') openCookieAcceptModal: ElementRef;

  constructor(private cookieService: CookieService) {
  }

  ngOnInit(): void {
    this.Check_Cookie_Exists();
  }

  Accept_Cookies() {
    this.cookieService.set("AcceptAllCookie", "true", 365);
    console.log("Cookie Accepted");
  }

  Open_Cookie_Alert() {
    //this.openCookieAcceptModal.nativeElement.click(); //??? nem mukodik igy
    let that = this;
    setTimeout(function () {
      that.openCookieAcceptModal.nativeElement.click();
    }, 100);
  }

  Check_Cookie_Exists() {
    if (!this.cookieService.check("AcceptAllCookie")) {
      this.Open_Cookie_Alert();
    }
  }

}
