<div class="row justify-content-center mb-5">

  <div class="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8
       text-center -content-Element my-2 py-2">
    <h1 class="-unique-Font"><i class="fas fa-user-edit mr-1"></i> Profil</h1>
  </div>

  <div class="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8
       text-center -content-Element">

    <div class="row my-3">
      <div class="col-12 col-sm-6 my-2 my-sm-0">
        <div class="row">
          <div class="col-5 text-left -profile-Item"><i class="fas fa-id-card"></i> Vezetéknév</div>
          <input type="text" class="col-6 ml-auto mr-3 -profile-Item-Value" [ngModel]="user.lastname" disabled>
        </div>
      </div>
      <div class="col-12 col-sm-6 my-2 my-sm-0">
        <div class="row">
          <div class="col-5 text-left -profile-Item"><i class="fas fa-id-card"></i> Keresztnév</div>
          <input type="text" class="col-6 ml-auto mr-3 -profile-Item-Value" [ngModel]="user.firstname" disabled>
        </div>
      </div>
    </div>

    <div class="row my-3">
      <div class="col-12 col-sm-6 my-2 my-sm-0">
        <div class="row">
          <div class="col-5 text-left -profile-Item"><i class="fas fa-fingerprint"></i> Felhasználónév</div>
          <input type="text" class="col-6 ml-auto mr-3 -profile-Item-Value" [ngModel]="user.username" disabled>
        </div>
      </div>
      <div class="col-12 col-sm-6 my-2 my-sm-0">
        <div class="row">
          <div class="col-5 text-left -profile-Item"><i class="fas fa-signature"></i> Kijelzett név</div>
          <input type="text" class="col-6 ml-auto mr-3 -profile-Item-Value" [ngModel]="user.displayName" disabled>
        </div>
      </div>
    </div>

    <div class="row my-3">
      <div class="col-12">
        <div class="row">
          <div class="col-5 col-sm-2 text-left -profile-Item"><i class="fas fa-envelope"></i> Email</div>
          <input type="text" class="col-6 col-sm-9 ml-auto mr-3 -profile-Item-Value" [ngModel]="user.email" disabled>
        </div>
      </div>
    </div>

    <div class="row my-3">
      <div class="col-12 col-sm-6 my-2 my-sm-0">
        <div class="row">
          <div class="col-5 text-left -profile-Item"><i class="fas fa-venus-mars"></i> Nem</div>
          <input type="text" class="col-6 ml-auto mr-3 -profile-Item-Value" [ngModel]="user.gender" disabled>
        </div>
      </div>
      <div class="col-12 col-sm-6 my-2 my-sm-0">
        <div class="row">
          <div class="col-5 text-left -profile-Item"><i class="fas fa-table"></i> Születési dátum</div>
          <input type="text" class="col-6 ml-auto mr-3 -profile-Item-Value" [ngModel]="user.birthDate" disabled>
        </div>
      </div>
    </div>

  </div>

  <div class="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8
       text-center -content-Element p-4 my-2">

    <div class="row justify-content-around">

      <a href="https://www.account.ckcorps.com/PROFILE"
         class="btn btn-outline-success col-12 col-md-5 mb-3 mb-md-0"
         target="_blank">
        <i class="fas fa-exchange-alt mr-2"></i> Profil adatok módosítása
      </a>

      <button class="btn btn-outline-primary col-12 col-md-5"
              (click)="Delete_Login_Data()">
        <i class="fas fa-trash-alt mr-2"></i> Bejelentkezési adataim törlése
      </button>

      <button *ngIf="user.accessLevel > 9" routerLink="/Admin"
      class="btn btn-outline-warning col-12 col-md-11 mt-3">
        <i class="fas fa-user-shield"></i> Adminfelület
      </button>

    </div>

  </div>

    <div class="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8
       text-center -content-Element mb-5 mb-md-0 mt-2 py-2">
    <app-ads></app-ads>
  </div>

</div>

<!--ERROR MSG MODAL-->
<div class="modal fade" id="_Error_Msg_Modal" tabindex="-2" role="dialog" aria-hidden="true" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered" role="document" id="_Modal_Cookie_Dialog">
    <div class="modal-content bg-dark text-white mx-3" id="_Modal_Cookie_Content">
      <div class="modal-header" id="_Modal_Cookie_Header">
        <h5 class="modal-title w-100 text-center text-warning" id="_Modal_Cookie_H5">
          
        </h5>
      </div>
      <div class="modal-body" id="_Modal_Cookie_Body">

        <p class="text-justify mx-3">
          Bejelentkezési adatait törölte a böngésző memóriájából!
        </p>

      </div>
      <div class="modal-footer" id="_Modal_CK_Cookie_Footer">
        <button type="button" class="btn btn-block btn-outline-secondary text-white mx-3 mb-3"
                id="_Accept_Cookie_Btn" data-dismiss="modal" #closeCookieModal>
          <i class="fas fa-check-double"></i> Rendben
        </button> <!--data-dismiss="modal"-->
      </div>
    </div>
  </div>
</div>

<button class="btn -hide-Element" data-toggle="modal"
        data-target="#_Error_Msg_Modal"
        type="submit" #errorMsgModal>
</button>
