import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { CookieService } from 'ngx-cookie-service';

import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { ContentComponent } from './content/content.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { AppRoutingModule } from './app-routing.module';
import { EditorComponent } from './editor/editor.component';
import { MylistComponent } from './mylist/mylist.component';
import { CookiealertComponent } from './cookiealert/cookiealert.component';
import { LegalComponent } from './legal/legal.component';
import { ListitemComponent } from './listitem/listitem.component';
import { CompletelistComponent } from './completelist/completelist.component';
import { CompletelistitemComponent } from './completelistitem/completelistitem.component';
import { AdsComponent } from './ads/ads.component';
import { AdminComponent } from './admin/admin.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    ContentComponent,
    HomeComponent,
    ProfileComponent,
    EditorComponent,
    MylistComponent,
    CookiealertComponent,
    LegalComponent,
    ListitemComponent,
    CompletelistComponent,
    CompletelistitemComponent,
    AdsComponent,
    AdminComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [ CookieService ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor() {
    
  }

  ngOnInit(): void {

  }

}
