<div class="row justify-content-center mb-5">

  <div class="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8
       text-center -content-Element">
    <h1 class="-unique-Font"><i class="fas fa-list ml-2 mr-1"></i> Listáim</h1>
  </div>

  <div class="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8
       text-center -content-Element my-2 py-2">
    <small>
      A termék kosárba tétele nem mentődik ezen az oldalon!
      Amennyiben szerkeszteni szeretné a listát, kattintson a szerkesztés ikonra (<i class="fas fa-edit ml-2 mr-1"></i>).
    </small>
  </div>

  <div class="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8
       text-center -content-Element my-2 py-2">
    <app-ads></app-ads>
  </div>

  <div class="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8 m-0 p-0">
    <div class="col-12">
      <div class="row justify-content-between" id="accordion">
        <app-completelist *ngFor="let myList of list"
                          class="col-12 col-md-5 col-lg-3 mr-1 my-4"
                          [list]="myList"
                          (Refresh_Data)="Refresh_Data($event)">

        </app-completelist>
      </div>
    </div>
  </div>

  <div class="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8
       text-center -content-Element mb-5 mb-md-0 mt-2 py-2">
    <app-ads></app-ads>
  </div>

</div>

<!--ERROR MSG MODAL-->
<div class="modal fade" id="_Error_Msg_Modal" tabindex="-2" role="dialog" aria-hidden="true" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered" role="document" id="_Modal_Cookie_Dialog">
    <div class="modal-content bg-dark text-white mx-3" id="_Modal_Cookie_Content">
      <div class="modal-header" id="_Modal_Cookie_Header">
        <h5 class="modal-title w-100 text-center text-warning" id="_Modal_Cookie_H5">
          {{ errorMsgType }}
        </h5>
      </div>
      <div class="modal-body" id="_Modal_Cookie_Body">

        <p class="text-justify mx-3" innerHtml="{{ errorMsg }}"></p>

      </div>
      <div class="modal-footer" id="_Modal_CK_Cookie_Footer">
        <button type="button" class="btn btn-block btn-outline-secondary text-white mx-3 mb-3"
                id="_Accept_Cookie_Btn" data-dismiss="modal" #closeCookieModal>
          <i class="fas fa-check-double"></i> Rendben
        </button> <!--data-dismiss="modal"-->
      </div>
    </div>
  </div>
</div>

<button class="btn -hide-Element" data-toggle="modal"
        data-target="#_Error_Msg_Modal"
        type="submit" #errorMsgModal>
</button>


<!--SHARE LIST MODAL-->
<div class="modal fade" id="_Share_List_Modal" tabindex="-2" role="dialog" aria-hidden="true" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered" role="document" id="_Modal_Cookie_Dialog">
    <div class="modal-content bg-dark text-white mx-3" id="_Modal_Cookie_Content">
      <div class="modal-header" id="_Modal_Cookie_Header">
        <h5 class="modal-title w-100 text-center text-warning" id="_Modal_Cookie_H5">
          {{ errorMsgType }}
        </h5>
      </div>

      <div class="modal-body" id="_Modal_Cookie_Body">

        <div class="row w-100 justify-content-between">
          <div class="col-12 col-md-4 my-3 -list-Item text-center">Felhasználónév: </div>
          <input type="text" class="col-12 col-md-7 -list-Item-Value mx-2 my-3"
                 [(ngModel)]="tmpUsername">
        </div>

      </div>

      <div class="modal-footer" id="_Modal_CK_Cookie_Footer">

        <div class="row w-100 justify-content-between">
          <button type="button" class="col-7 btn btn-outline-success"
                  (click)="Share_List()"
                  data-dismiss="modal">
            <i class="fas fa-share-alt"></i> Lista megosztása
          </button>
          <button type="button" class="col-4 btn btn-outline-danger"
                  data-dismiss="modal">
            <i class="fas fa-external-link-square-alt mr-1"></i> Mégse
          </button>
        </div>

      </div>
    </div>
  </div>
</div>

<button class="btn -hide-Element" data-toggle="modal"
        data-target="#_Share_List_Modal"
        type="submit" #shareListModal>
</button>


<!--DELETE LIST MODAL-->
<div class="modal fade" id="_Delete_List_Modal" tabindex="-2" role="dialog" aria-hidden="true" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered" role="document" id="_Modal_Cookie_Dialog">
    <div class="modal-content bg-dark text-white mx-3" id="_Modal_Cookie_Content">
      <div class="modal-header" id="_Modal_Cookie_Header">
        <h5 class="modal-title w-100 text-center text-warning" id="_Modal_Cookie_H5">
          {{ errorMsgType }}
        </h5>
      </div>

      <div class="modal-body" id="_Modal_Cookie_Body">

        <p class="text-justify mx-3" innerHtml="{{ errorMsg }}"></p>

      </div>

      <div class="modal-footer" id="_Modal_CK_Cookie_Footer">

        <div class="row w-100 justify-content-between">
          <button type="button" class="col-7 btn btn-outline-success"
                  data-dismiss="modal"
                  (click)="Yes_Delete_List()">
            <i class="fas fa-trash"></i> Törlés
          </button>
          <button type="button" class="col-4 btn btn-outline-danger"
                  data-dismiss="modal">
            <i class="fas fa-external-link-square-alt mr-1"></i> Mégse
          </button>
        </div>

      </div>
    </div>
  </div>
</div>

<button class="btn -hide-Element" data-toggle="modal"
        data-target="#_Delete_List_Modal"
        type="submit" #deleteListModal>
</button>
