<div class="col-12 -component my-3 px-4"
     *ngIf="!product.deleted; else restoreElement">

  <div class="row">

    <div class="col-10 col-md-3 -list-Item my-2">Termék neve*</div>

    <button class="btn col-2 d-md-none my-2"
            [ngClass]="{'btn-success': !product.productState, 'btn-danger': product.productState}"
            (click)="Refresh_Parent_Data(product.productId, 'change-state')">
      <i class="fas fa-times-circle" *ngIf="product.productState; else _Disable_Product_1"></i>
      <ng-template #_Disable_Product_1>
        <i class="fas fa-shopping-cart"></i>
      </ng-template>
    </button>

    <input type="text" class="col-12 col-md-7 -list-Item-Value my-2"
           [(ngModel)]="product.productName">

    <button class="btn col-1 d-none d-md-block my-2 ml-auto"
            [ngClass]="{'btn-success': !product.productState, 'btn-danger': product.productState}"
            (click)="Refresh_Parent_Data(product.productId, 'change-state')">
      <i class="fas fa-times-circle" *ngIf="product.productState; else _Disable_Product_2"></i>
      <ng-template #_Disable_Product_2>
        <i class="fas fa-shopping-cart"></i>
      </ng-template>
    </button>

  </div>

  <div class="row">
    <div class="col-5 col-md-2 -list-Item my-2">Egységár*</div>
    <input type="text" class="col-7 col-md-2 -list-Item-Value my-2"
           [(ngModel)]="product.productPrice"
           (change)="Refresh_Parent_Data(product.productId, 'change-price', product.productPrice)">
    <div class="col-6 col-md-2 -list-Item my-2">Mennyiség*</div>
    <input type="number" class="col-6 col-md-2 -list-Item-Value my-2" min="1"
           [(ngModel)]="product.productQuantity"
           (change)="Refresh_Parent_Data(product.productId, 'change-price', product.productPrice)">

    <div class="col-6 col-md-2 -list-Item my-2">Mértékegység*</div>
    <select class="col-6 col-md-2 -list-Item-Value my-2 ml-auto"
            [(ngModel)]="product.productUnit">
      <option *ngFor="let unit of productUnits"
              [value]="unit">
        {{ unit }}
      </option>
    </select>
  </div>

  <div class="row">
    <div class="col-5 col-md-3 -list-Item my-2">Helyszín</div>
    <input type="text" class="col-7 col-md-6 -list-Item-Value my-2" [(ngModel)]="product.productLocation">
    <button class="btn btn-outline-danger col-12 col-md-2 my-2 ml-auto"
            (click)="Refresh_Parent_Data(product.productId, 'delete-element')">
      <i class="fas fa-trash-alt"></i>
    </button>
  </div>

  <div class="row text-white mb-2">
    <div class="col-6 text-right ml-auto"><small class="-dotted-Place">termék ára: {{ product.productFullPrice }}</small></div>
  </div>

</div>


<ng-template #restoreElement>
  <div class="col-12 -component my-3 px-4 py-2">

    <div class="row">

      <div class="col-11 col-md-4 text-center">{{ product.productName }} - {{ product.productQuantity }} {{ product.productUnit }} </div>

      <div class="col-11 col-md-3 text-danger text-center">törölve</div>

      <button class="col-11 col-md-4 btn btn-outline-light mx-auto"
              (click)="Refresh_Parent_Data(product.productId, 'restore-element')">
        <i class="fas fa-undo-alt"></i> visszaállítás
      </button>

    </div>

  </div>
</ng-template>


