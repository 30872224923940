<div class="row justify-content-center mb-5">

  <div class="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8
       text-center -content-Element my-2 py-2">
    <h1 class="-unique-Font"><i class="fas fa-user-shield"></i> Adminfelület</h1>
  </div>

  <div class="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8
       text-center -content-Element">

    <div class="row my-3">

      <div class="col-12 my-2">
        Új mértékegység hozzáadása
      </div>

      <div class="col-7 mt-1">
        <input type="text" class="col-12 -profile-Item-Value" [(ngModel)]="newUnit">
      </div>

      <div class="col-5 text-left">
        <button class="btn btn-outline-success col-12"
          (click)="Add_Unit()">
          <i class="fas fa-plus-circle"></i> Hozzáad
        </button>
      </div>

    </div>

  </div>

  <div class="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8
       text-center -content-Element p-4 my-2">

    <div class="row my-3" *ngFor="let listItemUnit of listItemUnits">

      <div class="col-7 text-center -profile-Item">
        {{listItemUnit.unitName}}
      </div>

      <div class="col-5 text-left">
        <button class="btn btn-outline-danger col-12"
          (click)="Delete_Unit(listItemUnit.unitId)">
          <i class="fas fa-trash-alt"></i> Mértékegység eltávolítása
        </button>
      </div>

    </div>

  </div>

</div>

<!--ERROR MSG MODAL-->
<div class="modal fade" id="_Error_Msg_Modal" tabindex="-2" role="dialog" aria-hidden="true" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered" role="document" id="_Modal_Cookie_Dialog">
    <div class="modal-content bg-dark text-white mx-3" id="_Modal_Cookie_Content">
      <div class="modal-header" id="_Modal_Cookie_Header">
        <h5 class="modal-title w-100 text-center text-warning" id="_Modal_Cookie_H5">
          
        </h5>
      </div>
      <div class="modal-body" id="_Modal_Cookie_Body">

        <p class="text-justify mx-3">
          {{modalErrorMsg}}
        </p>

      </div>
      <div class="modal-footer" id="_Modal_CK_Cookie_Footer">
        <div class="spinner-grow mx-auto text-light" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</div>

<button class="btn -hide-Element" data-toggle="modal"
        data-target="#_Error_Msg_Modal"
        type="submit" #errorMsgModal>
</button>
