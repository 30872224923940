<nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-bottom d-flex d-md-none">
  <!-- NAVBAR MOBILE -->
  <a class="navbar-brand" href="/">
    <img src="assets/images/logo.png" alt="logo" class="-brand-Image">
    <span class="-brand-Text -unique-Font mx-1">CalcuList</span>
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNav">

    <ul class="navbar-nav mx-auto mx-lg-0 text-center">

      <li class="nav-item active mt-2">
        <a class="nav-link -my-Hover" routerLink="/Home" href="#">
          <i class="fas fa-home mr-1"></i> Kezdőoldal
        </a>
      </li>

    </ul>

    <ul class="navbar-nav mx-auto mx-lg-0 text-center">

      <li class="nav-item active">
        <a class="nav-link -my-Hover" routerLink="/Editor" href="#">
          <i class="fas fa-edit mr-1"></i> Szerkesztő
        </a>
      </li>

    </ul>

    <ul *ngIf="user.loggedIn" class="navbar-nav mx-auto mx-lg-0 text-center">

      <li class="nav-item active">
        <a class="nav-link -my-Hover" routerLink="/Mylist" href="#">
          <i class="fas fa-list mr-1"></i> Listáim
        </a>
      </li>

    </ul>

    <ul class="navbar-nav mx-auto mx-lg-0 ml-lg-auto text-center">

      <li *ngIf="!user.loggedIn" class="nav-item mt-2 mt-lg-0 mr-lg-3">
        <button class="btn btn-block btn-outline-success my-2 my-sm-0" data-toggle="modal" data-target="#_Login_Modal" type="submit">
          <i class="fas fa-sign-in-alt mr-1"></i> Bejelentkezés
        </button>
      </li>

      <li *ngIf="!user.loggedIn; else logOutMob" class="nav-item my-2 my-lg-0 mr-lg-2">
        <a href="https://www.account.ckcorps.com/REGISTRATION/?regRedirectUrl={{ myUrl }}"
           target="_blank"
           class="btn btn-block btn-outline-primary my-2 my-sm-0">
          <i class="fas fa-keyboard mr-1"></i> Regisztráció
        </a>
      </li>

      <ng-template #logOutMob>
        <li class="nav-item dropdown my-2 my-lg-0 mr-lg-1">
          <a class="nav-link dropdown-toggle text-info btn-lg" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="-font-Default"><i class="fas fa-user mr-1"></i> {{ user.username }} </span>
          </a>
          <div class="dropdown-menu dropdown-menu-right bg-dark" aria-labelledby="navbarDropdown">
            <a class="dropdown-item text-center text-white -my-Hover" routerLink="/Profile" href="#">
              <i class="fas fa-user-edit mr-1"></i> Profil
            </a>
            <button class="dropdown-item text-center text-white -my-Hover" (click)="User_Logout()">
              <i class="fas fa-sign-out-alt mr-1"></i> Kijelentkezés
            </button>
          </div>
        </li>
      </ng-template>

      <ul class="navbar-nav mx-auto mx-lg-0 text-center">

        <li class="nav-item active">
          <app-footer></app-footer>
        </li>

      </ul>
      
    </ul>

  </div>
</nav>

<nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top d-none d-md-flex">
  <!-- NAVBAR PC -->
  <a class="navbar-brand" href="/">
    <img src="assets/images/logo.png" alt="logo" class="-brand-Image">
    <span class="-brand-Text -unique-Font mx-3">CalcuList</span>
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNav">

    <ul class="navbar-nav mx-auto mx-lg-0 text-center">

      <li class="nav-item active">
        <a class="nav-link -my-Hover mx-2" routerLink="/Home" href="#">
          <i class="fas fa-home mx-1"></i> Kezdőoldal
        </a>
      </li>

    </ul>

    <ul class="navbar-nav mx-auto mx-lg-0 text-center">

      <li class="nav-item active">
        <a class="nav-link -my-Hover mx-2" routerLink="/Editor" href="#">
          <i class="fas fa-edit ml-2 mr-1"></i> Szerkesztő
        </a>
      </li>

    </ul>

    <ul *ngIf="user.loggedIn" class="navbar-nav mx-auto mx-lg-0 text-center">

      <li class="nav-item active">
        <a class="nav-link -my-Hover mx-2" routerLink="/Mylist" href="#">
          <i class="fas fa-list ml-2 mr-1"></i> Listáim
        </a>
      </li>

    </ul>

    <ul class="navbar-nav mx-auto mx-lg-0 ml-lg-auto text-center">

      <li *ngIf="!user.loggedIn" class="nav-item mt-2 mt-lg-0 mr-lg-3">
        <button class="btn btn-block btn-outline-success my-2 my-sm-0" data-toggle="modal" data-target="#_Login_Modal" type="submit">
          <i class="fas fa-sign-in-alt mr-1"></i> Bejelentkezés
        </button>
      </li>

      <li *ngIf="!user.loggedIn; else logOutPC" class="nav-item my-2 my-lg-0 mr-lg-2">
        <a href="https://www.account.ckcorps.com/REGISTRATION/?regRedirectUrl={{ myUrl }}"
           target="_blank"
           class="btn btn-block btn-outline-primary my-2 my-sm-0">
          <i class="fas fa-keyboard mr-1"></i> Regisztráció
        </a>
      </li>

      <ng-template #logOutPC>
        <li class="nav-item dropdown my-2 my-lg-0 mr-lg-1">
          <a class="nav-link dropdown-toggle text-info btn-lg" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="-font-Default"><i class="fas fa-user mr-1"></i> {{ user.username }} </span>
          </a>
          <div class="dropdown-menu dropdown-menu-right bg-dark" aria-labelledby="navbarDropdown">
            <a class="dropdown-item text-center text-white -my-Hover" routerLink="/Profile" href="#">
              <i class="fas fa-user-edit mr-1"></i> Profil
            </a>
            <button class="dropdown-item text-center text-white -my-Hover" (click)="User_Logout()">
              <i class="fas fa-sign-out-alt mr-1"></i> Kijelentkezés
            </button>
          </div>
        </li>
      </ng-template>

    </ul>

  </div>
</nav>

<!-- LOGIN Modal -->
<div class="modal fade" id="_Login_Modal" tabindex="-1" role="dialog" aria-labelledby="_Login_Modal_Label" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <form
          (ngSubmit)="Try_Login()" #loginForm="ngForm"
          class="modal-content bg-dark text-white">
      <div class="modal-header">
        <h5 class="modal-title" id="_Login_Modal_Label">
          <i class="fas fa-sign-in-alt mr-1"></i> Bejelentkezés
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span class="text-danger" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="form-group">

          <label for="_Login_Identifier">
            <i class="fas fa-fingerprint"></i> Felhasználónév vagy <i class="fas fa-envelope"></i> Email cím
          </label>
          <input type="text"
                  [(ngModel)]="user.usernameOrEmail"
                  class="form-control"
                  id="_Login_Identifier"
                  name="usernameOrEmail"
                  aria-describedby="emailHelp"
                  placeholder="Felhasználónév/Email...">

        </div>

        <div class="form-group">

          <label for="_Login_Password">
            <i class="fas fa-key mr-1"></i> Jelszó
          </label>
          <input type="password"
                  [(ngModel)]="user.password"
                  class="form-control"
                  id="_Login_Password"
                  name="password"
                  placeholder="Jelszó...">

        </div>

        <div class="ml-3 mb-2">
          <input type="checkbox" class="form-check-input" id="exampleCheck1"
                 [checked]="saveLoginData" (change)="saveLoginData = !saveLoginData">
          <label class="form-check-label" for="exampleCheck1">Jegyezd meg az adataim</label>
        </div>

        <div class="border border-white rounded text-center p-2"
              [ngClass]="(loginErrorVisiblity)?'d-block':'d-none'">
          <span class="text-warning" [innerHTML]="loginErrorMsg"></span>
        </div>

      </div>
      <div class="modal-footer">
        <button type="submit"
                [disabled]="loginSubmitted"
                class="btn btn-outline-success">
          <i class="fas fa-sign-in-alt mr-1"></i> Bejelentkezés
        </button>
        <button type="button" class="btn btn-outline-danger mr-auto"
                data-dismiss="modal" #closeLoginModal>
          <i class="fas fa-external-link-square-alt mr-1"></i> Kilépés
        </button>
      </div>
    </form>
  </div>
</div>
