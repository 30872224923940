import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Product } from '../product.model';

@Component({
  selector: 'app-completelistitem',
  templateUrl: './completelistitem.component.html',
  styleUrls: ['./completelistitem.component.css']
})
export class CompletelistitemComponent implements OnInit {

  @Input() product: Product;
  @Output("Refresh_Parent_Data") Refresh_Parent_Data: EventEmitter<{ pId: number, pAction: string }> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  Refresh_Grandparent_Data(pId: number, pAction: string) {
    this.Refresh_Parent_Data.emit({ pId: pId, pAction: pAction });
  }

}
