import { Product } from './product.model';

export class List {

  listId: number;
  listIdDataBase: number;

  listName: string;
  listDescription: string;
  listAuthor: string;
  listOwner: string;
  listState: string;
  listProducts: Product[];

  listSubPrice: number;
  listFullPrice: number;

  constructor(listId: number, listName: string, listDescription: string,
    listAuthor: string, listOwner: string, listProducts: Product[], listState: string = "aktív") {

    this.listId = listId;
    this.listIdDataBase = null;
    this.listOwner = listOwner;

    this.listName = listName;
    this.listDescription = listDescription;
    this.listAuthor = listAuthor;
    this.listState = listState;
    this.listProducts = listProducts;

    this.Calculate_Prices();
  }

  /* Overwrite list after saving in the editor */
  Init(newList: List) {

//    this.listId = newList.listId;
    this.listIdDataBase = newList.listIdDataBase;

    this.listName = newList.listName;
    this.listDescription = newList.listDescription;
    this.listAuthor = newList.listAuthor;
    this.listOwner = newList.listOwner;
    this.listState = newList.listState;

    for (let i = 0; i < newList.listProducts.length; i++) {
      let numOfProducts = this.listProducts.length;
      if (numOfProducts - 1 < i) {
        this.listProducts.push(new Product(numOfProducts, "", 0, 0, "db", "", false));
      }

      this.listProducts[i].Init(newList.listProducts[i]);
    }

    this.Calculate_Prices();

  }


  Calculate_Prices(): void{

    let fullPrice = 0;
    let subPrice = 0;

    for (let i = 0; i < this.listProducts.length; i++) {

      this.listProducts[i].Product_Full_Price();

      if (!this.listProducts[i].deleted) {

        fullPrice += this.listProducts[i].productFullPrice;

        if (this.listProducts[i].productState) {

          subPrice += this.listProducts[i].productFullPrice;
        }
      }
    }

    this.listSubPrice = subPrice;
    this.listFullPrice = fullPrice;
  }

  Check_Format_Validity(): any{

    try {

      let msg;
      let numOfProduct = 0;

    /* Check length of list name */
      if (this.listName.trim().length < 2) {

        msg = "Ne hagyja üresen a \"Lista neve\" mezőt. Minimum 2 karakter beírása szükséges. <br><br>";
        msg += "Jelenleg beírt karakterek száma: " + this.listName.trim().length;
        throw new Error(msg);
      }

    /* Check products */
      for (let i = 0; i < this.listProducts.length; i++) {

        if (!this.listProducts[i].deleted) {

          numOfProduct++;

          /* Check length of product name */
          if (this.listProducts[i].productName.trim().length < 2) {

            msg = "Ne hagyja üresen a \"Termék neve\" mezőt. Minimum 2 karakter beírása szükséges. <br><br>";
            msg += "Jelenleg beírt karakterek száma: " + this.listProducts[i].productName.trim().length;
            throw new Error(msg);
          }

          /* Check price of product */
          if (isNaN(this.listProducts[i].productPrice) ||
            this.listProducts[i].productPrice.toString() == "" ||
            this.listProducts[i].productPrice == null) {

            msg = "Valódi számot írjon be az \"Egységár\" mezőhöz a következő terméknél: " + this.listProducts[i].productName.trim();
            msg +=  "<br><br>";
            msg += "A mező jelenlegi tartalma: " + this.listProducts[i].productPrice;
            throw new Error(msg);
          }

          /* Check price of product */
          if (isNaN(this.listProducts[i].productQuantity) ||
            this.listProducts[i].productQuantity == null) {

            msg = "Valódi számot írjon be az \"Mennyiség\" mezőhöz a következő terméknél: " + this.listProducts[i].productName.trim();
            msg +=  "<br><br>";
            msg += "A mező jelenlegi tartalma: " + this.listProducts[i].productQuantity;
            throw new Error(msg);
          }

        }
      }

    /* Check number of not deleted products */
      if (numOfProduct < 1) {

        msg = "Legalább egy nem törölt terméket adjon hozzá a listához!";
        throw new Error(msg);
      }

      return true;
    } catch (error) {

      return error.message
    }
  }

}
