import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Router } from "@angular/router";

import { User } from '../user.model';
import { Unit } from '../unit.model';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

    @ViewChild('errorMsgModal') errorMsgModal: ElementRef;

    test: boolean;
    newUnit: string;
    modalErrorMsg: string;

    serverPath: string;
    options: {
      headers: HttpHeaders,
      withCredentials: boolean
    };
    body: HttpParams;

    user: User;
    listItemUnits: Unit[];

  constructor(private http: HttpClient,
    private cookieService: CookieService,
    private router: Router) {

    this.test = true;
    this.newUnit = "";
    this.modalErrorMsg = "";

    this.serverPath = "https://www.calculist.ckcorps.com/BACK-END/public";
    //this.serverPath = "http://localhost:8000";
    this.options = {
      headers: new HttpHeaders(),
      withCredentials: true
    };
    this.body = new HttpParams();
    this.options.headers = this.options.headers.append('Content-Type', 'application/x-www-form-urlencoded');
    this.options.headers = this.options.headers.append('X-CSRF-TOKEN', this.cookieService.get("XSRF-TOKEN"));

    this.user = new User();
    this.Has_Access();

    this.listItemUnits = [];
  }

  ngOnInit(): void {

    this.Get_Units();
    //this.Show_Error();
  }

  Has_Access() {
    if (!this.user.loggedIn) {
      this.router.navigate(['/Home']);
    }
  }

  Add_Unit(): void{
    let url = this.serverPath + "/newunit";
    this.body = this.body.set('unitName', JSON.stringify(this.newUnit));
    this.options.headers = this.options.headers.set('X-CSRF-TOKEN', this.cookieService.get("XSRF-TOKEN"));

    this.modalErrorMsg = "Új mértékegység hozzáadása. Kérlek várj...";
    this.Show_Error();

    this.http.post(url, this.body, this.options).subscribe(
      (val: {success: boolean, newUnits: Unit[]}) => {
        this.listItemUnits = val.newUnits;
        (this.test ? console.log(val) : "");
        this.Show_Error();
      },
      response => {

      },
      () => {
        //console.log("The POST observable is now completed.");
      });
  }

  Delete_Unit(unitId: number): void{
    let url = this.serverPath + "/deleteunit";
    this.body = this.body.set('unitId', JSON.stringify(unitId));
    this.options.headers = this.options.headers.set('X-CSRF-TOKEN', this.cookieService.get("XSRF-TOKEN"));

    this.modalErrorMsg = "Mértékegység letörlése. Kérlek várj...";
    this.Show_Error();

    this.http.post(url, this.body, this.options).subscribe(
      (val: {success: boolean, newUnits: Unit[]}) => {
        this.listItemUnits = val.newUnits;
        (this.test ? console.log(val) : "");
        this.Show_Error();
      },
      response => {

      },
      () => {
        //console.log("The POST observable is now completed.");
    });
  }

  Show_Error() {

    let that = this;
    setTimeout(function () {
      that.errorMsgModal.nativeElement.click();
    }, 100);
  }

  Get_Units(): void{
    let url = this.serverPath + "/api/getunits";

    this.http.get(url, this.options).subscribe(
      (val: Unit[]) => {
        this.listItemUnits = val;
        (this.test ? console.log(val) : "");
      },
      response => {

      },
      () => {
        //console.log("The POST observable is now completed.");
    });
  }


}
