<div class="card -complete-List">

  <div class="card-header mb-0 pb-0" attr.id="_Heading_{{list.listId}}">
    <div class="row justify-content-center">

      <button class="col-12 btn btn-info text-white" data-toggle="collapse" attr.data-target="#_Collapse_{{list.listId}}" aria-expanded="true" attr.aria-controls="_Collapse_{{list.listId}}">
        {{list.listName}}
      </button>

      <div class="col-12 row justify-content-between m-0 p-0">

        <button class="col-4 btn btn-outline-primary my-2"
                (click)="Refresh_Data_FHere_To_Parent(0, list.listIdDataBase+123, 'modify-list', '')">
          <i class="fas fa-edit ml-2 mr-1"></i>
        </button>

        <select class="custom-select col-7 my-2 text-white bg-dark"
                [(ngModel)]="list.listState"
                (change)="Refresh_Data_FHere_To_Parent(0, list.listIdDataBase, 'change-status', list.listState)">
          <option value="aktív">Aktív</option>
          <option value="inaktiv">Inaktiv</option>
          <option value="archivált">Archivált</option>
        </select>

        <button class="col-12 btn btn-outline-secondary text-white"
                (click)="Refresh_Data_FHere_To_Parent(0, list.listIdDataBase, 'share-list', list.listName)">
          <i class="fas fa-share-alt"></i> Megosztás
        </button>

      </div>

    </div>
  </div>

  <div attr.id="_Collapse_{{list.listId}}" class="collapse" attr.aria-labelledby="_Heading_{{list.listId}}" data-parent="#accordion">
    <div class="card-body my-0 py-0">

      <div class="col-12 my-3">
        <q>
          {{list.listDescription}}
        </q>
      </div>

      <app-completelistitem *ngFor="let myProduct of list.listProducts"
                            [product]="myProduct"
                            (Refresh_Parent_Data)="Refresh_Data_Child_To_Parent($event)">

      </app-completelistitem>

      <button class="btn btn-block btn-danger mt-3"
              (click)="Refresh_Data_FHere_To_Parent(0, list.listIdDataBase, 'delete-list', list.listName)">
        <i class="fas fa-trash-alt"></i> Lista törlése
      </button>

      <hr class="bg-white">
      <div class="row">
        <h6 class="col-12 text-center mb-3"><i class="fas fa-money-bill-wave ml-1"></i> Részösszeg: </h6>
        <h6 class="col-12 text-center"> {{ list.listSubPrice }}</h6>
      </div>

    </div>
  </div>

  <div class="card-footer my-0 py-0">
    <hr class="bg-white">
    <div class="row">
      <h5 class="col-12 text-center mb-3"><i class="fas fa-wallet ml-1"></i> Végösszeg: </h5>
      <h5 class="col-12 text-center"> {{ list.listFullPrice }}</h5>
    </div>
    <hr class="bg-white">
    <div class="text-white text-center mb-2"><small>Szerző: {{ list.listAuthor }}</small></div>
  </div>

</div>

