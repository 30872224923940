<div class="modal fade bg-secondary" id="_Modal_Cookie_Accept" tabindex="-2" role="dialog" aria-hidden="true" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered" role="document" id="_Modal_Cookie_Dialog">
    <div class="modal-content bg-dark text-white mx-3" id="_Modal_Cookie_Content">
      <div class="modal-header" id="_Modal_Cookie_Header">
        <h5 class="modal-title w-100 text-center" id="_Modal_Cookie_H5">
          Sütik
        </h5>
      </div>
      <div class="modal-body" id="_Modal_Cookie_Body">

        <p class="text-justify mx-3">
          Az oldal használatával tudomásul veszem és elfogadom, hogy a jelenleg látogatott weboldal
          <strong class="font-italic">cookie</strong>-kat (<strong class="font-italic">sütiket</strong>) használ a lehető legjobb minőségű felhasználói élmény nyújtása érdekében!
          <br>
          Továbbá beleegyezem az oldal felhasználási feltételeibe!
        </p>

        <div class="text-center">
          <a class="-ck-links text-center" href="https://hu.wikipedia.org/wiki/HTTP-s%C3%BCti" target="_blank">Mi az a süti?</a>
        </div>

      </div>
      <div class="modal-footer" id="_Modal_CK_Cookie_Footer">
        <button type="button" class="btn btn-block btn-outline-secondary text-white mx-3 mb-3"
                id="_Accept_Cookie_Btn" data-dismiss="modal" #closeCookieModal
                (click)="Accept_Cookies()">
          <i class="fas fa-check-double"></i> Értem, és elfogadom
        </button> <!--data-dismiss="modal"-->
      </div>
    </div>
  </div>
</div>

<button class="btn -hide-Element" data-toggle="modal"
        data-target="#_Modal_Cookie_Accept"
        type="submit" #openCookieAcceptModal>
</button>
